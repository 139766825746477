/* eslint-disable arrow-body-style */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable arrow-parens */
const getters = {
  // Generic
  generic: (state) => (config) => state[config.store],

  // Cobrands
  cobrands: (state) => state.cobrands,
  // Specific cobrand based on id
  cobrand: (state) => (id) =>
    state.cobrands.data.find((cobrand) => {
      return cobrand.id === id;
    }),

  // Menu
  menus: (state) => state.menus,

  // Specific menu based on id
  menu: (state) => (id) =>
    state.menus.data.find((menu) => menu.id === parseInt(id, 0)),

  mainBookNowBtnEnabled: (state) => state.mainBookNowBtnEnabled,
};

export default getters;
