<template>
  <div
    class="relative"
    @mouseover="showOptions = true"
    @mouseout="showOptions = false"
  >
    <div class="relative flex items-center">
      {{ currentLanguage }}
      <chevron-down-icon
        style="width: 12px; height: 12px; margin-bottom: 2px; margin-left: 2px;"
      ></chevron-down-icon>
    </div>
    <ul
      class="bg-black text-white absolute top-0 left-0 z-50"
      v-show="showOptions"
    >
      <li
        class="border-b border-grey"
        v-for="language in currentPage.languages"
        :key="language.id"
        @click.prevent="switchLanguage(language.url)"
      >
        <a href="" class="p-4 block text-white">{{ language.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LanguageSelector",
  components: {
    ChevronDownIcon: () => import("vue-feather-icons/icons/ChevronDownIcon"),
  },
  data() {
    return {
      showOptions: false,
    };
  },
  computed: {
    currentLanguage() {
      return this.currentPage.language.name;
    },
  },
  methods: {
    switchLanguage(url) {
      window.location = url;
    },
  },
};
</script>
