/* eslint-disable comma-dangle */
import commonUtils from "devisephp-interface/src/vuex/utils/common";

const actions = {
  // Generic
  getGeneric(context, payload) {
    return new Promise((resolve) => {
      window.axios
        .get(
          `${context.state.api.baseUrl}${
            payload.config.apiendpoint
          }/?${commonUtils.buildFilterParams(payload.filters)}`
        )
        .then((response) => {
          context.commit("setGeneric", { config: payload.config, response });
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  getGenericRecord(context, payload) {
    return new Promise((resolve) => {
      window.axios
        .get(
          `${context.state.api.baseUrl}${payload.config.apiendpoint}/${payload.id}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  createGeneric(context, payload) {
    return new Promise((resolve) => {
      window.axios
        .post(
          `${context.state.api.baseUrl}${payload.config.apiendpoint}/`,
          payload.record
        )
        .then((response) => {
          window.deviseSettings.$bus.$emit("showMessage", {
            title: "Success!",
            message: `${
              payload.record[payload.config.recordLabel]
            } has been created.`,
          });
          resolve(response);
        })

        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  updateGeneric(context, payload) {
    return new Promise((resolve) => {
      window.axios
        .put(
          `${context.state.api.baseUrl}${payload.config.apiendpoint}/${payload.record.id}`,
          payload.record
        )
        .then((response) => {
          window.deviseSettings.$bus.$emit("showMessage", {
            title: "Success!",
            message: `${
              payload.record[payload.config.recordLabel]
            } has been saved.`,
          });
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  deleteGeneric(context, payload) {
    return new Promise((resolve) => {
      window.axios
        .delete(
          `${context.state.api.baseUrl}${payload.config.apiendpoint}/${payload.record.id}`
        )
        .then((response) => {
          window.deviseSettings.$bus.$emit("showMessage", {
            title: "Success!",
            message: `${
              payload.record[payload.config.recordLabel]
            } has been deleted.`,
          });
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  // Categories
  getCategoryList(context, payload) {
    return new Promise((resolve) => {
      let query = "";
      // eslint-disable-next-line no-prototype-builtins
      if (payload.hasOwnProperty("parent_id")) {
        query = `?parent_id=${payload.parent_id}`;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (payload.hasOwnProperty("language_id")) {
        query += `&language_id=${payload.language_id}`;
      }
      window.axios
        .get(`${context.state.api.baseUrl}category-list${query}`)
        .then((response) => {
          context.commit("setCategoryList", response);
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  getEventCategoryList(context) {
    return new Promise((resolve) => {
      window.axios
        .get(`${context.state.api.baseUrl}event-category-list`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  getBlogPostsCategoryList(context) {
    return new Promise((resolve) => {
      window.axios
        .get(`${context.state.api.baseUrl}blog-posts-category-list`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  // Cobrands
  searchPageElements(context, payload) {
    return new Promise((resolve) => {
      window.axios
        .get(
          `${context.state.api.baseUrl}pages/${payload.page.id}/elements/${payload.model}`,
          {
            params: {
              query: payload.query,
            },
          }
        )
        .then((response) => {
          context.commit("setGeneric", { config: payload.config, response });
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  // Page Elements
  getPageElements(context, payload) {
    return new Promise((resolve) => {
      window.axios
        .get(`${context.state.api.baseUrl}pages/${payload.page.id}/elements`)
        .then((response) => {
          context.commit("setPageElements", response.data);
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  updatePageElements(context, payload) {
    return new Promise((resolve) => {
      window.axios
        .post(
          `${context.state.api.baseUrl}pages/${payload.page.id}/elements`,
          payload.elements
        )
        .then((response) => {
          window.deviseSettings.$bus.$emit("showMessage", {
            title: "Success!",
            message: `${payload.page.name} elements have been saved.`,
          });
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  // Rooms
  getTags(context) {
    return new Promise((resolve) => {
      window.axios
        .get(`${context.state.api.baseUrl}tags`)
        .then((response) => {
          context.commit("setTags", response);
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  // Rooms
  getRoomList(context) {
    return new Promise((resolve) => {
      window.axios
        .get(`${context.state.api.baseUrl}room-list`)
        .then((response) => {
          context.commit("setRoomList", response);
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  // Towers
  getTowerList(context) {
    return new Promise((resolve) => {
      window.axios
        .get(`${context.state.api.baseUrl}tower-list`)
        .then((response) => {
          context.commit("setTowerList", response);
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  // Menus
  getMenus(context) {
    return new Promise((resolve) => {
      window.axios
        .get(`${context.state.api.baseUrl}menus/`)
        .then((response) => {
          context.commit("setMenus", response.data);
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  createMenu(context, menu) {
    return new Promise((resolve) => {
      window.axios
        .post(`${context.state.api.baseUrl}menus/`, menu)
        .then((response) => {
          window.deviseSettings.$bus.$emit("showMessage", {
            title: "Success!",
            message: `${menu.name} has been created.`,
          });
          context.commit("createMenus", response.data);
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  updateMenu(context, payload) {
    return new Promise((resolve) => {
      window.axios
        .put(
          `${context.state.api.baseUrl}menus/${payload.menu.id}`,
          payload.data
        )
        .then((response) => {
          window.deviseSettings.$bus.$emit("showMessage", {
            title: "Success!",
            message: `${payload.data.name} has been saved.`,
          });
          context.commit("updateMenus", {
            menu: payload.menu,
            data: response.data,
          });
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  deleteMenu(context, menu) {
    return new Promise((resolve) => {
      window.axios
        .delete(`${context.state.api.baseUrl}menus/${menu.id}`)
        .then((response) => {
          window.deviseSettings.$bus.$emit("showMessage", {
            title: "Success!",
            message: `${menu.name} has been deleted.`,
          });
          context.commit("deleteMenus", menu);
          resolve(response);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },

  // UserRoles
  getUserRoles(context) {
    return new Promise((resolve) => {
      window.axios
        .get(`${context.state.api.baseUrl}user-roles/`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          window.deviseSettings.$bus.$emit("showError", error);
        });
    }).catch((error) => {
      window.deviseSettings.$bus.$emit("showError", error);
    });
  },
};

export default actions;
