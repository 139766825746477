<script>
export default {
  data() {
    return {
      hideBookNow: false,
    };
  },

  mounted() {
    window.deviseSettings.$bus.$on("hide-navation-book-now", () => {
      this.hideBookNow = true;
    });
  },
};
</script>
