/* eslint-disable implicit-arrow-linebreak */
import Vue from 'vue';
import VueRouter from 'vue-router';

const MenusAdmin = () => import('../components/devise-admin/menus/Admin.vue');
const BookingLinkQueryStringsAdmin = () =>
  import('../components/devise-admin/booking-link-query-strings/Admin.vue');
const ABTestsAdmin = () =>
  import('../components/devise-admin/ab-tests/Admin.vue');
const ABTestsAdminCreate = () =>
  import('../components/devise-admin/ab-tests/Create.vue');
const ABTestsAdminEdit = () =>
  import('../components/devise-admin/ab-tests/Edit.vue');
const BookingFaqsAdmin = () =>
  import('../components/devise-admin/booking-faqs/Admin.vue');
const BookingMessagesAdmin = () =>
  import('../components/devise-admin/booking-messages/Admin.vue');
const BookingOffersAdmin = () =>
  import('../components/devise-admin/booking-offers/Admin.vue');
const ChatbotPagesAdmin = () =>
  import('../components/devise-admin/chatbot-pages/Admin.vue');
const ChatbotFeedbackAdmin = () =>
  import('../components/devise-admin/chatbot-feedback/Admin.vue');
const BlogPostsAdmin = () =>
  import('../components/devise-admin/blog-posts/Admin.vue');
const PressReleasesAdmin = () =>
  import('../components/devise-admin/press-releases/Admin.vue');
const CalendarAlertsAdmin = () =>
  import('../components/devise-admin/calendar-alerts/Admin.vue');
const CobrandsAdmin = () =>
  import('../components/devise-admin/cobrands/Admin.vue');
const EventsAdmin = () => import('../components/devise-admin/events/Admin.vue');
const FormSignupsAdmin = () =>
  import('../components/devise-admin/form-signups/Admin.vue');
const GolfProsAdmin = () =>
  import('../components/devise-admin/golf-pros/Admin.vue');
const NewslettersAdmin = () =>
  import('../components/devise-admin/newsletters/Admin.vue');
const PdfsAdmin = () => import('../components/devise-admin/pdfs/Admin.vue');
const ErrorLogsAdmin = () =>
  import('../components/devise-admin/error-logs/Admin.vue');
const MoviesAdmin = () => import('../components/devise-admin/movies/Admin.vue');
const WaitlistAdmin = () =>
  import('../components/devise-admin/wait-list/Admin.vue');
const RestaurantsAdmin = () =>
  import('../components/devise-admin/restaurants/Admin.vue');
const RoomsAdmin = () => import('../components/devise-admin/rooms/Admin.vue');
const RoomTypesAdmin = () =>
  import('../components/devise-admin/room-types/Admin.vue');
const SpecialsAdmin = () =>
  import('../components/devise-admin/specials/Admin.vue');
const SpecialsCategoriesAdmin = () =>
  import('../components/devise-admin/specials-categories/Admin.vue');
const TiersAdmin = () => import('../components/devise-admin/tiers/Admin.vue');
const TowersAdmin = () => import('../components/devise-admin/towers/Admin.vue');
const TravelAgentsAdmin = () =>
  import('../components/devise-admin/travel-agents/Admin.vue');
const PageElementsManager = () =>
  import('../components/devise-admin/page-elements/Manage.vue');
const UsersAtlAdmin = () =>
  import('../components/devise-admin/users/Admin.vue');
const RedirectsAtlAdmin = () =>
  import('../components/devise-admin/redirects/Admin.vue');
const RouteCacheRefreshAdmin = () =>
  import('../components/devise-admin/route-cache/Admin.vue');
const SeoAdmin = () => import('../components/devise-admin/seo/Admin.vue');

const routes = [
  // You don't want to remove this. This is the Main administration menu for
  // Devise. This allows you to add new menu items to the primary navigation.
  // {
  //   path: '/devise',
  //   name: 'devise-index',
  //   components: {
  //     'devise': MainMenu
  //   }
  // },
  // Categories
  // {
  //   path: '/devise/categories',
  //   name: 'devise-categories-index',
  //   components: {
  //     devise: CategoriesAdmin,
  //   },
  //   meta: {
  //     title: 'Manage categories',
  //
  //     allow: ['manage categories'],
  //   },
  // },
  // AB Tests
  {
    path: '/devise/ab-tests',
    name: 'devise-ab-tests-admin',
    components: {
      devise: ABTestsAdmin,
    },
    meta: {
      title: 'View AB Tests List',
      allow: ['manage users'],
    },
  },
  {
    path: '/devise/chatbot-pages',
    name: 'devise-chatbot-pages-admin',
    components: {
      devise: ChatbotPagesAdmin,
    },
    meta: {
      title: 'Chatbot Pages List',
      allow: ['manage users'],
    },
  },
  {
    path: '/devise/chatbot-feedback',
    name: 'devise-chatbot-feedback-admin',
    components: {
      devise: ChatbotFeedbackAdmin,
    },
    meta: {
      title: 'Chatbot Feedback List',
      allow: ['manage users'],
    },
  },
  // BlogPosts
  {
    path: '/devise/blog-posts',
    name: 'devise-blog-posts-admin',
    components: {
      devise: BlogPostsAdmin,
    },
    meta: {
      title: 'Manage Blog Posts',
      allow: ['manage blog-posts'],
    },
  },
  {
    path: '/devise/ab-tests/create',
    name: 'devise-ab-tests-admin-create',
    components: {
      devise: ABTestsAdminCreate,
    },
    meta: {
      title: 'Create AB Tests Create',
      allow: ['manage users'],
    },
  },
  {
    path: '/devise/ab-tests/:testId/edit',
    name: 'devise-ab-tests-admin-edit',
    components: {
      devise: ABTestsAdminEdit,
    },
    meta: {
      title: 'Create AB Tests Edit',
      allow: ['manage users'],
    },
  },
  // BookingLinkQueryStrings
  {
    path: '/devise/booking-link-query-strings',
    name: 'devise-booking-link-query-strings-admin',
    components: {
      devise: BookingLinkQueryStringsAdmin,
    },
    meta: {
      title: 'Manage booking-link-query-strings',
      allow: ['manage booking-link-query-strings'],
    },
  },
  // BookingFaqs
  {
    path: '/devise/booking-faqs',
    name: 'devise-booking-faqs-admin',
    components: {
      devise: BookingFaqsAdmin,
    },
    meta: {
      title: 'Manage booking-faqs',
      allow: ['manage booking-faqs'],
    },
  },
  // BookingMessages
  {
    path: '/devise/booking-messages',
    name: 'devise-booking-messages-admin',
    components: {
      devise: BookingMessagesAdmin,
    },
    meta: {
      title: 'Manage booking-messages',
      allow: ['manage booking-messages'],
    },
  },
  // BookingOffers
  {
    path: '/devise/booking-offers',
    name: 'devise-booking-offers-admin',
    components: {
      devise: BookingOffersAdmin,
    },
    permissions: 'manage booking offers',
    meta: {
      title: 'Manage booking-offers',
      allow: ['manage booking-offers'],
    },
  },
  // CalendarAlerts
  {
    path: '/devise/calendar-alerts',
    name: 'devise-calendar-alerts-admin',
    components: {
      devise: CalendarAlertsAdmin,
    },
    meta: {
      title: 'Manage calendar alerts',
      allow: ['manage calendar alerts'],
    },
  },
  // Cobrands
  {
    path: '/devise/cobrands',
    name: 'devise-cobrands-admin',
    components: {
      devise: CobrandsAdmin,
    },
    meta: {
      title: 'Manage cobrands',
      allow: ['manage cobrands'],
    },
  },
  // Events
  {
    path: '/devise/events',
    name: 'devise-events-admin',
    components: {
      devise: EventsAdmin,
    },
    meta: {
      title: 'Manage events',
      allow: ['manage events'],
    },
  },
  // Menus
  {
    path: '/devise/menus',
    name: 'devise-menus-admin',
    components: {
      devise: MenusAdmin,
    },
    meta: {
      title: 'Manage Menus',
      allow: ['manage menus'],
    },
  },
  // Newsletters
  {
    path: '/devise/newsletters',
    name: 'devise-newsletters-admin',
    components: {
      devise: NewslettersAdmin,
    },
    meta: {
      title: 'Manage newsletters',
      allow: ['manage newsletters'],
    },
  },
  // Page Elements
  {
    path: '/devise/page-elements',
    name: 'devise-page-elements-manager',
    components: {
      devise: PageElementsManager,
    },
    meta: {
      title: 'Manage page elements',
      allow: ['manage page elements'],
    },
  },
  // Restaurants
  {
    path: '/devise/restaurants',
    name: 'devise-restaurants-admin',
    components: {
      devise: RestaurantsAdmin,
    },
    meta: {
      title: 'Manage restaurants',
      allow: ['manage restaurants'],
    },
  },
  // Rooms
  {
    path: '/devise/rooms',
    name: 'devise-rooms-admin',
    components: {
      devise: RoomsAdmin,
    },
    meta: {
      title: 'Manage rooms',
      allow: ['manage rooms'],
    },
  },
  // Room Types
  {
    path: '/devise/room-types',
    name: 'devise-room-types-admin',
    components: {
      devise: RoomTypesAdmin,
    },
    meta: {
      title: 'Manage room types',
      allow: ['manage room types'],
    },
  },
  // SEO
  {
    path: '/devise/seo',
    name: 'devise-seo-admin',
    components: {
      devise: SeoAdmin,
    },
    meta: {
      title: 'Manage SEO',
      allow: ['manage events'],
    },
  },
  // Specials
  {
    path: '/devise/specials',
    name: 'devise-specials-admin',
    components: {
      devise: SpecialsAdmin,
    },
    meta: {
      title: 'Manage specials',
      allow: ['manage specials'],
    },
  },
  // Specials Categories
  {
    path: '/devise/specials-categories',
    name: 'devise-specials-categories-admin',
    components: {
      devise: SpecialsCategoriesAdmin,
    },
    meta: {
      title: 'Manage specials categories',
      allow: ['manage specials categories'],
    },
  },
  // Tiers
  {
    path: '/devise/tiers',
    name: 'devise-tiers-admin',
    components: {
      devise: TiersAdmin,
    },
    meta: {
      title: 'Manage tiers',
      allow: ['manage tiers'],
    },
  },
  // Towers
  {
    path: '/devise/towers',
    name: 'devise-towers-admin',
    components: {
      devise: TowersAdmin,
    },
    meta: {
      title: 'Manage towers',
      allow: ['manage towers'],
    },
  },
  // Pdf
  {
    path: '/devise/pdfs',
    name: 'devise-pdfs-admin',
    components: {
      devise: PdfsAdmin,
    },
    meta: {
      title: 'Manage pdfs',
      allow: ['manage pdfs'],
    },
  },
  // Press Releases
  {
    path: '/devise/press-releases',
    name: 'devise-press-releases-admin',
    components: {
      devise: PressReleasesAdmin,
    },
    meta: {
      title: 'Manage Press Releases',
      allow: ['manage press releases'],
    },
  },
  // Error Logs
  {
    path: '/devise/booking-error-logs',
    name: 'devise-error-logs-admin',
    components: {
      devise: ErrorLogsAdmin,
    },
    meta: {
      title: 'Manage Error Logs',
      allow: ['manage calendar alerts'],
    },
  },
  // Waitlist
  {
    path: '/devise/waitlist',
    name: 'devise-waitlist-admin',
    components: {
      devise: WaitlistAdmin,
    },
    meta: {
      title: 'View Waiting List',
      allow: ['manage calendar alerts'],
    },
  },
  // Movie Tiems
  {
    path: '/devise/movies',
    name: 'devise-movies-admin',
    components: {
      devise: MoviesAdmin,
    },
    meta: {
      title: 'View Movie Times',
      allow: ['manage movies'],
    },
  },
  // Travel Agents
  {
    path: '/devise/travel-agents',
    name: 'devise-travel-agents-admin',
    components: {
      devise: TravelAgentsAdmin,
    },
    meta: {
      title: 'Manage Travel Agents',
      allow: ['manage travel agents'],
    },
  },
  // Form Signups
  {
    path: '/devise/form-signups',
    name: 'devise-form-signups-admin',
    components: {
      devise: FormSignupsAdmin,
    },
    meta: {
      title: 'Manage Form Signups',
      allow: ['manage form signups'],
    },
  },
  // Golf Pros
  {
    path: '/devise/golf-pros',
    name: 'devise-golf-pros-admin',
    components: {
      devise: GolfProsAdmin,
    },
    meta: {
      title: 'Manage Golf Pros',
      allow: ['manage golf pros'],
    },
  },
  // New users admin
  {
    path: '/devise/users/:userId/edit',
    name: 'devise-users-atl-admin',
    components: {
      devise: UsersAtlAdmin,
    },
    meta: {
      parentRouteName: 'devise-settings',
    },
    props: true,
  },
  // New redirects admin
  {
    path: '/devise/redirects',
    name: 'devise-redirects-atl-admin',
    components: {
      devise: RedirectsAtlAdmin,
    },
    meta: {
      title: 'Manage Form Signups',
      allow: ['manage redirects'],
    },
  },
  // Route Cache Refresh
  {
    path: '/devise/route-cache/refresh',
    name: 'devise-route-refresh-admin',
    components: {
      devise: RouteCacheRefreshAdmin,
    },
    meta: {
      title: 'Route Cache Refresh',
      allow: ['manage redirects'],
    },
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'abstract',
  transitionOnLoad: true,
  routes,
});

export default router;
