<template>
  <div class="relative" @click="showOptions = !showOptions">
    <div class="relative flex items-center">
      {{ currentLanguage }}
      <chevron-down-icon
        style="width: 12px; height: 12px; margin-bottom: 2px; margin-left: 2px"
      ></chevron-down-icon>
    </div>
    <ul
      class="
        bg-white
        text-gray-400
        absolute
        top-0
        left-0
        z-50
        text-left
        mt-8
        md:mt-4
      "
      v-show="showOptions"
    >
      <li
        class="border-b border-gray-300"
        v-for="language in currentPage.languages"
        :key="language.id"
        @click.prevent="switchLanguage(language.url)"
      >
        <a
          href=""
          class="
            p-2
            pr-10
            pl-4
            block
            text-gray-500
            hover:bg-glueblue-300
            hover:text-gray-800
            tranform-colors
            duration-150
          "
          >{{ language.name }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LanguageSelector",
  components: {
    ChevronDownIcon: () => import("vue-feather-icons/icons/ChevronDownIcon"),
  },
  data() {
    return {
      showOptions: false,
    };
  },
  computed: {
    currentLanguage() {
      return this.currentPage.language.name;
    },
  },
  methods: {
    switchLanguage(url) {
      window.location = url;
    },
  },
};
</script>
