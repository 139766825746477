<template>
  <!-- eslint-disable max-len -->
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="110.6 353.5 946.5 141"
    enable-background="new 110.6 353.5 946.5 141"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          :fill="fill"
          d="M649.4,375.1c-3.7-3.7-9.1-6.2-14.5-6.2c-10.3,0-19,9.1-19,19.4c0,10.3,8.7,19.4,19.4,19.4c5.4,0,10.3-2.5,14.5-6.2v6.6c-4.1,2.9-9.1,4.5-14.1,4.5c-13.2,0-24.8-10.7-24.8-24.4c0-13.6,11.2-24.8,24.8-24.8c5.4,0,9.9,1.7,14.1,4.5v7H649.4z"
        ></path>
        <path
          :fill="fill"
          d="M712.3,388.3c0,13.6-11.2,24.4-24.8,24.4c-13.6,0-24.8-10.7-24.8-24.4c0-13.6,11.2-24.4,24.8-24.4C701.1,363.9,712.3,374.6,712.3,388.3z M668,388.3c0,10.7,8.7,19.4,19.4,19.4c10.7,0,19.4-9.1,19.4-19.4c0-10.7-8.7-19.4-19.4-19.4C676.7,368.9,668,377.5,668,388.3z"
        ></path>
        <path
          :fill="fill"
          d="M870.6,375.1c-3.7-3.7-9.1-6.2-14.5-6.2c-10.7,0-19,9.1-19,19.4c0,10.3,8.7,19.4,19,19.4c5.4,0,10.3-2.5,14.5-6.2v6.6c-4.1,2.9-9.1,4.5-14.1,4.5c-13.2,0-24.8-10.7-24.8-24.4c0-13.6,11.2-24.8,24.8-24.8c5.4,0,9.9,1.7,14.1,4.5V375.1z"
        ></path>
        <path
          :fill="fill"
          d="M732.5,406.9h13.2v5h-18.2v-47.1h5.4v42.2H732.5z"
        ></path>
        <path
          :fill="fill"
          d="M766.4,406.9h13.2v5h-18.2v-47.1h5.4v42.2H766.4z"
        ></path>
        <path
          :fill="fill"
          d="M794.5,364.7h24.4v5h-19.4v14.1h18.6v5h-18.6v18.6h19.4v5h-24.4V364.7z"
        ></path>
        <path
          :fill="fill"
          d="M899.5,411.9h-5.4v-42.2h-11.6v-5h28.1v5h-11.6v42.2H899.5z"
        ></path>
        <path :fill="fill" d="M928.9,411.9h-5.4v-47.1h5.4V411.9z"></path>
        <path
          :fill="fill"
          d="M992.5,388.3c0,13.6-11.2,24.4-24.8,24.4s-24.8-10.7-24.8-24.4c0-13.6,11.2-24.4,24.8-24.4C981.4,363.9,992.5,374.6,992.5,388.3z M948.3,388.3c0,10.7,8.7,19.4,19.4,19.4c10.7,0,19.4-9.1,19.4-19.4c0-10.7-8.7-19.4-19.4-19.4S948.3,377.5,948.3,388.3z"
        ></path>
        <path
          :fill="fill"
          d="M1006.6,362.7l36.8,38.9v-36.8h5.4v49.6l-36.8-38.4v36.4h-5.4V362.7z"
        ></path>
        <path
          :fill="fill"
          d="M182.3,392.8c0,3.3,0,7.4,2.1,10.3c2.1,2.9,6.2,4.5,9.5,4.5s7-1.7,9.1-4.1c2.5-2.9,2.5-7,2.5-10.7v-28.1h5.4v29.8c0,5-0.4,9.1-4.1,13.2c-3.3,3.3-7.9,5.4-12.8,5.4c-4.5,0-9.1-1.7-12.4-5c-4.1-4.1-4.5-8.3-4.5-13.6v-29.8h5.4L182.3,392.8L182.3,392.8z"
        ></path>
        <path
          :fill="fill"
          d="M241.8,411.9h-5.4v-42.2h-11.6v-5H253v5h-11.6v42.2H241.8z"
        ></path>
        <path
          :fill="fill"
          d="M312.9,388.3c0,13.6-11.2,24.4-24.8,24.4s-24.8-10.7-24.8-24.4c0-13.6,11.2-24.4,24.8-24.4C301.8,363.9,312.9,374.6,312.9,388.3z M268.7,388.3c0,10.7,8.7,19.4,19.4,19.4s19.4-9.1,19.4-19.4c0-10.7-8.7-19.4-19.4-19.4S268.7,377.5,268.7,388.3z"
        ></path>
        <path
          :fill="fill"
          d="M492.8,411.9h-5v-47.1h7.9c4.1,0,8.3,0,11.6,2.5c3.7,2.5,5.4,6.6,5.4,10.7c0,3.7-1.7,7.9-4.5,10.3c-3.3,2.5-7.4,3.3-11.2,3.3h-3.7v20.3H492.8z M492.8,386.2h3.3c5.8,0,10.7-1.7,10.7-8.7c0-7.9-6.2-8.3-12.4-8.3h-2.1v16.9H492.8z"
        ></path>
        <path
          :fill="fill"
          d="M557.2,386.2v-21.9h5.4v47.1h-5.4v-20.7h-24.4v20.7h-5.4v-46.7h5.4v21.9h24.4V386.2z"
        ></path>
        <path
          :fill="fill"
          d="M411.7,411.9h6.2l-12.8-21.9c1.2-0.4,2.5-1.2,3.3-2.1c2.9-2.5,4.5-6.2,4.5-10.3s-1.7-8.3-5.4-10.7c-3.3-2.5-7.9-2.5-11.6-2.5h-7.9v47.1h5.4v-20.7v-5v-16.9h2.1c6.2,0,12.4,0.4,12.4,8.3c0,6.6-5,8.3-10.3,8.7L411.7,411.9z"
        ></path>
        <path
          :fill="fill"
          d="M372.5,388.3h-19.8c2.1,1.2,3.7,2.9,5,5h9.1c0,4.1-1.7,7.4-4.5,9.9c-1.2,1.2-2.9,2.5-5,3.3c-2.5,1.2-5,1.7-7.4,1.7c-5,0-9.9-2.5-13.2-6.2c-3.3-3.3-5.4-7.4-5.4-12.4c0-0.4,0-0.8,0-1.2c0-1.2,0-2.9,0.4-4.1c2.1-8.7,9.5-15.7,19-15.7c7,0,11.6,3.3,15.7,9.1l3.7-3.7c-5-6.6-11.2-10.3-19.4-10.3c-13.2,0-23.6,9.9-24.4,22.7c0,0.4,0,1.2,0,1.7c0,1.2,0,2.9,0.4,4.1c2.1,11.6,12,20.3,24,20.3c2.5,0,5-0.4,7-1.2c2.1-0.8,3.7-1.7,5.8-2.5c1.7-1.2,3.3-2.5,5-4.1C371.6,399.9,372.5,394.5,372.5,388.3z"
        ></path>
        <path
          :fill="fill"
          d="M121.5,411.9h5.8l7-16.5c7.9,2.1,14.5,6.2,20.3,12c1.7,1.7,2.9,3.3,4.1,5l0,0h5.8l0,0l-21.1-49.6l-12,27.3c-1.7-0.4-3.3-0.4-5-0.8c1.2,1.7,2.1,3.3,2.9,5.4L121.5,411.9z M136.4,390.8l7-16.1l10.3,25.2C148.8,395.7,143,392.8,136.4,390.8z"
        ></path>
        <path
          :fill="fill"
          d="M452.7,362.2l-12,27.3c-1.7-0.4-3.3-0.4-5-0.8c1.2,1.7,2.1,3.3,2.9,5.4l0,0l0,0l-7.9,17.8h2.5h3.3l7-16.5c7.9,2.1,14.5,6.2,20.3,12c1.7,1.7,2.9,3.3,4.1,5l0,0h5.8l0,0L452.7,362.2z M445.6,390.8l7-16.1l10.7,25.2C458,395.7,451.8,392.8,445.6,390.8z"
        ></path>
      </g>
      <g>
        <path
          :fill="fill"
          d="M551,465.6c0,10.3-8.3,18.2-18.2,18.2c-9.9,0-18.2-7.9-18.2-18.2c0-9.9,8.3-18.2,18.2-18.2C542.8,447.4,551,455.7,551,465.6z M546.9,465.6c0-7.9-5.8-14.1-14.1-14.1s-14.1,6.2-14.1,14.1s6.2,14.1,14.1,14.1C540.3,479.6,546.9,473.4,546.9,465.6z"
        ></path>
        <path
          :fill="fill"
          d="M580,483h-4.1v-30.6h-8.3v-4.1h20.7v4.1H580V483z"
        ></path>
        <path
          :fill="fill"
          d="M607.3,448.2h18.6v4.1h-14.1v9.9h14.1v4.1h-14.1v12.4h14.5v4.1h-18.6v-34.7H607.3z"
        ></path>
        <path
          :fill="fill"
          d="M651.1,478.8h10.7v4.1h-14.9v-34.7h4.5v30.6H651.1z"
        ></path>
        <path
          :fill="fill"
          d="M695.7,454.8c-1.2-2.1-2.9-3.3-5.4-3.3c-2.9,0-5.8,2.1-5.8,5c0,2.9,2.5,4.1,5,5l2.5,0.8c5,2.1,8.7,4.5,8.7,10.3c0,6.2-5,10.7-11.2,10.7c-5.8,0-9.9-4.1-10.7-9.5l4.5-0.8c0,3.7,2.9,6.6,6.6,6.6c3.7,0,6.2-3.3,6.2-6.6c0-3.7-2.9-5.4-5.8-6.6l-2.5-0.8c-4.1-1.7-7.4-4.1-7.4-8.7c0-5.8,5-9.1,10.3-9.1c3.7,0,7,2.1,9.1,5.4L695.7,454.8z"
        ></path>
        <polygon
          :fill="fill"
          points="491.1,448.2 491.1,463.5 474.6,463.5 474.6,448.2 470,448.2 470,483 474.6,483 474.6,467.7 491.1,467.7 491.1,483 495.2,483 495.2,448.2"
        ></polygon>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
