/* eslint-disable no-param-reassign */

export default {
  // Generic
  setGeneric(state, payload) {
    state[payload.config.store] = payload.response.data;
  },

  deleteGeneric(state, payload) {
    state[payload.config.store].data.splice(
      state.cobrands.data.indexOf(payload.record),
      1
    );
  },

  // Cobrands
  setCobrands(state, payload) {
    state.cobrands = payload;
  },

  setCobrandOverride(state, payload) {
    state.cobrandOverride = payload;
  },

  // Menus
  createMenus(state, menu) {
    state.menus.data.push(menu);
  },

  setMenus(state, payload) {
    state.menus = payload;
  },

  updateMenus(state, { menu, data }) {
    state.menus.data.splice(state.menus.data.indexOf(menu), 1, data);
  },

  deleteMenus(state, menu) {
    state.menus.data.splice(state.menus.data.indexOf(menu), 1);
  },

  // Page Elements
  setPageElements(state, data) {
    if (!(data instanceof Object)) {
      data = Object.assign(
        {},
        Object.assign({}, { Cobrand: [], Special: [], Event: [] })
      );
    }
    state.pageElements = data;
  },

  // Categories
  setCategoryList(state, payload) {
    state.categories = payload;
  },

  // Rooms
  setRoomList(state, payload) {
    state.rooms = payload;
  },

  // Tags
  setTags(state, payload) {
    state.tags = payload;
  },

  // Towers
  setTowerList(state, payload) {
    state.towers = payload;
  },

  // Book Now Button
  mainBookNowBtnEnabled(state, payload) {
    state.mainBookNowBtnEnabled = payload;
  },
};
