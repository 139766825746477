<template>
  <div class="overflow-hidden">
    <mobile-navigation :menu="menu" :show-book-now="false"></mobile-navigation>
  </div>
</template>

<script>
export default {
  name: 'GlueAppNavigation',

  components: {
    MobileNavigation: () => import('./MobileNavigation.vue'),
  },

  computed: {
    headerMenu() {
      return window.deviseSettings.$page.site.data.headerMenu;
    },
    menu() {
      return window.deviseSettings.$page.site.data.glueMainMenu;
    },
  },
};
</script>
