/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue';

import JQuery from 'jquery';

window.$ = JQuery;
window.jQuery = JQuery;

// Devise requires a bus, vue-router and vuex. We initialize these in your application
// so that both apps can share the same store and router. All devise vuex
// is namespaced under the "devise" namespace.
import { sync } from 'vuex-router-sync';

// Devise
import Devise from 'devisephp-interface';

// VCalendar
import VCalendar from 'v-calendar';

// YouTube
import VueYoutube from 'vue-youtube';

// Vimeo
import vueVimeoPlayer from 'vue-vimeo-player';

// Waypoint - Detects if things are coming in or going off the screen
import Waypoint from 'vue-waypoint';

// Slither Slider
import SlitherSlider from 'slither-slider';

// Vuex, Router, Bus
import store from './vuex/store';
import router from './router/route.config';
import EventBus from './event-bus';

import AnimateInView from './directives/animate-in-view';

import AppNavigation from './components/AppNavigation.vue';
import AppFooter from './components/AppFooter.vue';
import checkView from 'vue-check-view';
import LanguageSelector from './components/LanguageSelector.vue';
import AutographLogo from './components/AutographLogo.vue';
import SocialSharing from 'vue-social-sharing';

import GlueLanguageSelector from './components/glue/navigation/LanguageSelector.vue';
import GlueAppNavigation from './components/glue/navigation/AppNavigation.vue';
import GlueAppFooter from './components/glue/footer/AppFooter.vue';
import NewsletterFooterSignup from './components/glue/newsletter/FooterSignup.vue';

import SummerNavigation from './components/summer/Navigation.vue';

import BarbieNavigation from './components/barbie/navigation/AppNavigation.vue';
import BarbieFooter from './components/barbie/footer/AppFooter.vue';
import BarbieNewsletterFooterSignup from './components/glue/newsletter/BarbieFooterSignup.vue';

require('./bootstrap');

window.bus = EventBus;
sync(store, router);

// Plugin Use
Vue.use(Devise, {
  store,
  router,
  bus: window.bus,
  options: {
    adminClass: '',
  },
});
Vue.use(VCalendar);
Vue.use(VueYoutube);
Vue.use(vueVimeoPlayer);
Vue.use(Waypoint);
Vue.use(SlitherSlider);
Vue.use(SocialSharing);
Vue.use(checkView);

// Directives
Vue.directive('animate-in-view', AnimateInView);

// Admin
Vue.component('devise-blqs-create', () =>
  import(
    './components/devise-admin/booking-link-query-strings/steps/Create.vue'
  )
);
Vue.component('devise-blqs-edit', () =>
  import('./components/devise-admin/booking-link-query-strings/steps/Edit.vue')
);
Vue.component('devise-blqs-delete', () =>
  import(
    './components/devise-admin/booking-link-query-strings/steps/Delete.vue'
  )
);

Vue.component('devise-ab-tests-create', () =>
  import('./components/devise-admin/booking-messages/steps/Create.vue')
);

Vue.component('devise-booking-messages-create', () =>
  import('./components/devise-admin/booking-messages/steps/Create.vue')
);
Vue.component('devise-booking-messages-create-blocks', () =>
  import('./components/devise-admin/booking-messages/steps/CreateBlocks.vue')
);
Vue.component('devise-booking-messages-create-filters', () =>
  import('./components/devise-admin/booking-messages/steps/CreateFilters.vue')
);
Vue.component('devise-booking-messages-edit', () =>
  import('./components/devise-admin/booking-messages/steps/Edit.vue')
);
Vue.component('devise-booking-messages-edit-blocks', () =>
  import('./components/devise-admin/booking-messages/steps/EditBlocks.vue')
);
Vue.component('devise-booking-messages-edit-filters', () =>
  import('./components/devise-admin/booking-messages/steps/EditFilters.vue')
);
Vue.component('devise-booking-messages-delete', () =>
  import('./components/devise-admin/booking-messages/steps/Delete.vue')
);

Vue.component('devise-booking-offers-create', () =>
  import('./components/devise-admin/booking-offers/steps/Create.vue')
);
Vue.component('devise-booking-offers-create-filters', () =>
  import('./components/devise-admin/booking-offers/steps/CreateFilters.vue')
);
Vue.component('devise-booking-offers-edit', () =>
  import('./components/devise-admin/booking-offers/steps/Edit.vue')
);
Vue.component('devise-booking-offers-edit-filters', () =>
  import('./components/devise-admin/booking-offers/steps/EditFilters.vue')
);
Vue.component('devise-booking-offers-delete', () =>
  import('./components/devise-admin/booking-offers/steps/Delete.vue')
);

Vue.component('devise-blog-posts-create', () =>
  import('./components/devise-admin/blog-posts/steps/Create.vue')
);
Vue.component('devise-blog-posts-edit', () =>
  import('./components/devise-admin/blog-posts/steps/Edit.vue')
);
Vue.component('devise-blog-posts-delete', () =>
  import('./components/devise-admin/blog-posts/steps/Delete.vue')
);

Vue.component('devise-press-releases-create', () =>
  import('./components/devise-admin/press-releases/steps/Create.vue')
);
Vue.component('devise-press-releases-edit', () =>
  import('./components/devise-admin/press-releases/steps/Edit.vue')
);
Vue.component('devise-press-releases-delete', () =>
  import('./components/devise-admin/press-releases/steps/Delete.vue')
);

Vue.component('devise-cobrand-create', () =>
  import('./components/devise-admin/cobrands/steps/Create.vue')
);
Vue.component('devise-cobrand-create-limits', () =>
  import('./components/devise-admin/cobrands/steps/CreateLimits.vue')
);
Vue.component('devise-cobrand-edit', () =>
  import('./components/devise-admin/cobrands/steps/Edit.vue')
);
Vue.component('devise-cobrand-edit-limits', () =>
  import('./components/devise-admin/cobrands/steps/EditLimits.vue')
);
Vue.component('devise-cobrand-edit-usage', () =>
  import('./components/devise-admin/cobrands/steps/EditUsage.vue')
);
Vue.component('devise-cobrand-delete', () =>
  import('./components/devise-admin/cobrands/steps/Delete.vue')
);

Vue.component('devise-event-create', () =>
  import('./components/devise-admin/events/steps/Create.vue')
);
Vue.component('devise-event-create-details', () =>
  import('./components/devise-admin/events/steps/CreateDetails.vue')
);
Vue.component('devise-event-create-repeats', () =>
  import('./components/devise-admin/events/steps/CreateRepeats.vue')
);
Vue.component('devise-event-edit', () =>
  import('./components/devise-admin/events/steps/Edit.vue')
);
Vue.component('devise-event-edit-details', () =>
  import('./components/devise-admin/events/steps/EditDetails.vue')
);
Vue.component('devise-event-edit-repeats', () =>
  import('./components/devise-admin/events/steps/EditRepeats.vue')
);
Vue.component('devise-event-delete', () =>
  import('./components/devise-admin/events/steps/Delete.vue')
);

Vue.component('devise-seo-edit', () =>
  import('./components/devise-admin/seo/steps/Edit.vue')
);

Vue.component('devise-faq-create', () =>
  import('./components/devise-admin/booking-faqs/steps/Create.vue')
);
Vue.component('devise-faq-edit', () =>
  import('./components/devise-admin/booking-faqs/steps/Edit.vue')
);
Vue.component('devise-faq-delete', () =>
  import('./components/devise-admin/booking-faqs/steps/Delete.vue')
);

Vue.component('devise-form-signups-edit', () =>
  import('./components/devise-admin/form-signups/steps/Edit.vue')
);
Vue.component('devise-form-signups-delete', () =>
  import('./components/devise-admin/form-signups/steps/Delete.vue')
);

Vue.component('devise-golf-pros-create-bio', () =>
  import('./components/devise-admin/golf-pros/steps/CreateBio.vue')
);
Vue.component('devise-golf-pros-create-availability', () =>
  import('./components/devise-admin/golf-pros/steps/CreateAvailability.vue')
);
Vue.component('devise-golf-pros-edit-bio', () =>
  import('./components/devise-admin/golf-pros/steps/EditBio.vue')
);
Vue.component('devise-golf-pros-edit-availability', () =>
  import('./components/devise-admin/golf-pros/steps/EditAvailability.vue')
);
Vue.component('devise-golf-pros-delete', () =>
  import('./components/devise-admin/golf-pros/steps/Delete.vue')
);

Vue.component('devise-menu-create', () =>
  import('./components/devise-admin/menus/steps/Create.vue')
);
Vue.component('devise-menu-edit', () =>
  import('./components/devise-admin/menus/steps/Edit.vue')
);
Vue.component('devise-menu-delete', () =>
  import('./components/devise-admin/menus/steps/Delete.vue')
);

Vue.component('devise-newsletters-create', () =>
  import('./components/devise-admin/newsletters/steps/Create.vue')
);
Vue.component('devise-newsletters-edit', () =>
  import('./components/devise-admin/newsletters/steps/Edit.vue')
);
Vue.component('devise-newsletters-delete', () =>
  import('./components/devise-admin/newsletters/steps/Delete.vue')
);

Vue.component('devise-restaurants-create', () =>
  import('./components/devise-admin/restaurants/steps/Create.vue')
);
Vue.component('devise-restaurants-edit', () =>
  import('./components/devise-admin/restaurants/steps/Edit.vue')
);
Vue.component('devise-restaurants-delete', () =>
  import('./components/devise-admin/restaurants/steps/Delete.vue')
);

Vue.component('devise-rooms-create', () =>
  import('./components/devise-admin/rooms/steps/Create.vue')
);
Vue.component('devise-rooms-edit', () =>
  import('./components/devise-admin/rooms/steps/Edit.vue')
);
Vue.component('devise-rooms-delete', () =>
  import('./components/devise-admin/rooms/steps/Delete.vue')
);

Vue.component('devise-room-types-create', () =>
  import('./components/devise-admin/room-types/steps/Create.vue')
);
Vue.component('devise-room-types-edit', () =>
  import('./components/devise-admin/room-types/steps/Edit.vue')
);
Vue.component('devise-room-types-delete', () =>
  import('./components/devise-admin/room-types/steps/Delete.vue')
);

Vue.component('devise-special-create', () =>
  import('./components/devise-admin/specials/steps/Create.vue')
);
Vue.component('devise-special-create-images', () =>
  import('./components/devise-admin/specials/steps/CreateImages.vue')
);

Vue.component('devise-special-create-included-items', () =>
  import('./components/devise-admin/specials/steps/CreateIncludedItems.vue')
);
Vue.component('devise-special-create-configuration', () =>
  import('./components/devise-admin/specials/steps/CreateConfiguration.vue')
);
Vue.component('devise-special-edit-versions', () =>
  import('./components/devise-admin/specials/steps/EditVersions.vue')
);
Vue.component('devise-special-edit-content', () =>
  import('./components/devise-admin/specials/steps/EditContent.vue')
);

Vue.component('devise-special-edit-included-items', () =>
  import('./components/devise-admin/specials/steps/EditIncludedItems.vue')
);
Vue.component('devise-special-edit-images', () =>
  import('./components/devise-admin/specials/steps/EditImages.vue')
);
Vue.component('devise-special-edit-configuration', () =>
  import('./components/devise-admin/specials/steps/EditConfiguration.vue')
);
Vue.component('devise-special-delete', () =>
  import('./components/devise-admin/specials/steps/Delete.vue')
);

Vue.component('devise-special-create-blocks', () =>
  import('./components/devise-admin/specials/steps/CreateBlocks.vue')
);

Vue.component('devise-special-edit-blocks', () =>
  import('./components/devise-admin/specials/steps/EditBlocks.vue')
);

Vue.component('devise-special-categories-create', () =>
  import('./components/devise-admin/specials-categories/steps/Create.vue')
);
Vue.component('devise-special-categories-edit', () =>
  import('./components/devise-admin/specials-categories/steps/Edit.vue')
);
Vue.component('devise-special-categories-delete', () =>
  import('./components/devise-admin/specials-categories/steps/Delete.vue')
);

Vue.component('devise-tier-create', () =>
  import('./components/devise-admin/tiers/steps/Create.vue')
);
Vue.component('devise-tier-edit', () =>
  import('./components/devise-admin/tiers/steps/Edit.vue')
);
Vue.component('devise-tier-delete', () =>
  import('./components/devise-admin/tiers/steps/Delete.vue')
);

Vue.component('devise-tower-create', () =>
  import('./components/devise-admin/towers/steps/Create.vue')
);
Vue.component('devise-tower-edit', () =>
  import('./components/devise-admin/towers/steps/Edit.vue')
);

Vue.component('devise-travel-agent-create', () =>
  import('./components/devise-admin/travel-agents/steps/Create.vue')
);
Vue.component('devise-travel-agent-create-location', () =>
  import('./components/devise-admin/travel-agents/steps/CreateLocation.vue')
);
Vue.component('devise-travel-agent-edit', () =>
  import('./components/devise-admin/travel-agents/steps/Edit.vue')
);
Vue.component('devise-travel-agent-edit-location', () =>
  import('./components/devise-admin/travel-agents/steps/EditLocation.vue')
);
Vue.component('devise-travel-agent-delete', () =>
  import('./components/devise-admin/travel-agents/steps/Delete.vue')
);

Vue.component('devise-users-atl-create', () =>
  import('./components/devise-admin/users/steps/Create.vue')
);
Vue.component('devise-users-atl-edit', () =>
  import('./components/devise-admin/users/steps/Edit.vue')
);
Vue.component('devise-users-atl-delete', () =>
  import('./components/devise-admin/users/steps/Delete.vue')
);

Vue.component('devise-redirects-atl-create', () =>
  import('./components/devise-admin/redirects/steps/Create.vue')
);
Vue.component('devise-redirects-atl-edit', () =>
  import('./components/devise-admin/redirects/steps/Edit.vue')
);
Vue.component('devise-redirects-atl-delete', () =>
  import('./components/devise-admin/redirects/steps/Delete.vue')
);

Vue.component('modal-gallery', () =>
  import('./components/glue/galleries/ModalGallery.vue')
);
Vue.component('specials-page', () =>
  import('./components/glue/specials/SpecialPage.vue')
);
Vue.component('offer-border-card', () =>
  import('./components/glue/specials/OfferBorderCard.vue')
);
Vue.component('specials-categories', () =>
  import('./components/glue/specials/SpecialsCategories.vue')
);
Vue.component('special-deals', () =>
  import('./components/glue/specials/Deals.vue')
);
Vue.component('slider', () => import('./components/Slider.vue'));
Vue.component('glue-slider', () =>
  import('./components/glue/sliders/Slider.vue')
);

Vue.component('hotel-index', () =>
  import('./components/glue/stay/HotelIndex.vue')
);
Vue.component('hotel-card', () => import('./components/glue/stay/Hotel.vue'));
Vue.component('other-hotels', () =>
  import('./components/glue/stay/OtherHotels.vue')
);
Vue.component('other-hotel-card', () =>
  import('./components/glue/stay/OtherHotel.vue')
);

// Maps
Vue.component('interactive-map', () =>
  import('./components/glue/map/InteractiveMap.vue')
);
Vue.component('flight-map', () =>
  import('./components/glue/map/FlightMap.vue')
);
Vue.component('cabana-map', () =>
  import('./components/glue/map/CabanaMap.vue')
);
Vue.component('the-dig-features', () =>
  import('./components/glue/map/TheDigFeatures.vue')
);
Vue.component('toddlers-aka', () =>
  import('./components/glue/family/ToddlersAka.vue')
);
Vue.component('preteens-aka', () =>
  import('./components/glue/family/PreteensAka.vue')
);
Vue.component('shopping', () =>
  import('./components/glue/experience/Shopping.vue')
);
Vue.component('fitness-programs', () =>
  import('./components/glue/experience/FitnessPrograms.vue')
);

// Clipping Sliders
Vue.component('glue-clipping-slider', () =>
  import('./components/glue/sliders/ClippingSlider.vue')
);
Vue.component('clipped-slider-full-image-card', () =>
  import('./components/glue/sliders/ClippedSliderFullImageCard.vue')
);
Vue.component('glue-side-clipping-slider', () =>
  import('./components/glue/sliders/SideClippingSlider.vue')
);

Vue.component('three-image-grid-to-mobile-slider', () =>
  import('./components/glue/sliders/ThreeImageGridToMobileSlider.vue')
);
Vue.component('title-and-description-gallery', () =>
  import('./components/glue/sliders/TitleAndDescriptionGallery.vue')
);

Vue.component('slider-image', () =>
  import('./components/glue/sliders/SliderImage.vue')
);
Vue.component('slider-bottom-right-copy', () =>
  import('./components/glue/sliders/BottomRightCopy.vue')
);
Vue.component('slider-special-offer', () =>
  import('./components/glue/sliders/SpecialOffer.vue')
);
Vue.component('clipped-media', () =>
  import('./components/glue/media/ClippedMedia.vue')
);
Vue.component('vimeo-media', () => import('./components/glue/media/Vimeo.vue'));
Vue.component('wistia-media', () =>
  import('./components/glue/media/Wistia.vue')
);
Vue.component('clipped-card', () =>
  import('./components/glue/generic-cards/ClippedCard.vue')
);
Vue.component('image-card', () =>
  import('./components/glue/generic-cards/ImageCard.vue')
);
Vue.component('arrow-icon', () =>
  import('./components/glue/svgs/ui/Arrow.vue')
);
Vue.component('icon-card', () =>
  import('./components/glue/generic-cards/IconCard.vue')
);
Vue.component('one-to-three-cards-with-red-circle-icon', () =>
  import('./components/glue/generic-cards/OneToThreeCardsWithRedCircleIcon.vue')
);
Vue.component('contact-card', () =>
  import('./components/glue/generic-cards/ContactCard.vue')
);
Vue.component('flat-card', () =>
  import('./components/glue/generic-cards/FlatCard.vue')
);
Vue.component('description-card', () =>
  import('./components/glue/generic-cards/DescriptionCard.vue')
);
Vue.component('booking-card', () =>
  import('./components/glue/generic-cards/BookingCard.vue')
);
Vue.component('hotel-hero', () =>
  import('./components/glue/stay/HotelHero.vue')
);
Vue.component('general-title-and-copy', () =>
  import('./components/glue/titles-and-text/GeneralTitleAndCopy.vue')
);
Vue.component('room-type-list', () =>
  import('./components/glue/stay/RoomTypeList.vue')
);
Vue.component('room-type', () => import('./components/glue/stay/RoomType.vue'));
Vue.component('bed-type-list', () =>
  import('./components/glue/stay/BedTypeList.vue')
);
Vue.component('bed-type', () => import('./components/glue/stay/BedType.vue'));
Vue.component('featured-bed-types', () =>
  import('./components/glue/stay/FeaturedBedTypes.vue')
);
Vue.component('featured-bed-type', () =>
  import('./components/glue/stay/FeaturedBedType.vue')
);

Vue.component('dining-index', () =>
  import('./components/glue/dining/DiningIndex.vue')
);

Vue.component('dining-results', () =>
  import('./components/glue/dining/DiningResults.vue')
);

Vue.component('stay-grid-gallery', () =>
  import('./components/glue/stay/GridGallery.vue')
);
Vue.component('static-gridded-gallery', () =>
  import('./components/glue/galleries/StaticGriddedGallery.vue')
);
Vue.component('static-gridded-gallery-gallery', () =>
  import('./components/glue/galleries/StaticGriddedGalleryGallery.vue')
);
Vue.component('atlantis-logo', () =>
  import('./components/glue/svgs/AtlantisLogo.vue')
);
Vue.component('atlantis-annv-logo', () =>
  import('./components/glue/svgs/AtlantisAnnvLogo.vue')
);
Vue.component('anniversary-logo', () =>
  import('./components/glue/svgs/AnniversaryLogo.vue')
);
Vue.component('royal-logo', () => import('./components/logos/RoyalLogo.vue'));
Vue.component('cove-logo', () => import('./components/logos/CoveLogo.vue'));
Vue.component('reef-logo', () => import('./components/logos/ReefLogo.vue'));
Vue.component('coral-logo', () => import('./components/logos/CoralLogo.vue'));
Vue.component('harborside-logo', () =>
  import('./components/logos/HarborsideLogo.vue')
);

Vue.component('magazine-slider-container', () =>
  import('./components/glue/magazine-slider/MagazineSliderContainer')
);

Vue.component('magazine-slide', () =>
  import('./components/glue/magazine-slider/MagazineSlide')
);
Vue.component('magazine-feature', () =>
  import('./components/glue/magazine-feature/MagazineFeature')
);

Vue.component('amenities', () => import('./components/Amenities.vue'));
Vue.component('amenities-included', () =>
  import('./components/AmenitiesIncluded.vue')
);
Vue.component('book-now', () => import('./components/BookNow.vue'));

Vue.component('booking-form', () => import('./components/BookingForm.vue'));

Vue.component('glue-book-now', () =>
  import('./components/glue/booking/BookNow.vue')
);
Vue.component('glue-booking-form', () =>
  import('./components/glue/booking/BookingForm.vue')
);
Vue.component('waiting-list-form', () =>
  import('./components/glue/waiting-list/WaitingListForm.vue')
);

Vue.component('mini-inline-booking', () =>
  import('./components/MiniInlineBooking.vue')
);
Vue.component('glue-mini-inline-booking', () =>
  import('./components/glue/booking/MiniInlineBooking.vue')
);
Vue.component('search-activities', () =>
  import('./components/glue/activities/SearchActivities.vue')
);

Vue.component('events-by-day', () => import('./components/EventsByDay.vue'));
Vue.component('event-card', () =>
  import('./components/glue/events/EventCard.vue')
);
Vue.component('generic-horizontal-card', () =>
  import('./components/glue/generic-cards/HorizontalCard.vue')
);
Vue.component('daily-resort-fee', () =>
  import('./components/glue/hotel-features/DailyResortFee.vue')
);

Vue.component('form-signup', () => import('./components/FormSignup.vue'));

Vue.component('modal', () => import('./components/Modal.vue'));
Vue.component('devise-pdf-create', () =>
  import('./components/devise-admin/pdfs/steps/Create.vue')
);
Vue.component('devise-pdf-edit', () =>
  import('./components/devise-admin/pdfs/steps/Edit.vue')
);
Vue.component('devise-pdf-delete', () =>
  import('./components/devise-admin/pdfs/steps/Delete.vue')
);

Vue.component('devise-tower-create', () =>
  import('./components/devise-admin/towers/steps/Create.vue')
);
Vue.component('devise-tower-edit', () =>
  import('./components/devise-admin/towers/steps/Edit.vue')
);

Vue.component('grid-gallery', () => import('./components/GridGallery.vue'));
Vue.component('flex-gallery', () => import('./components/FlexGallery.vue'));

Vue.component('payment', () => import('./components/Payment.vue'));
Vue.component('reef-my-account', () =>
  import('./components/ReefMyAccount.vue')
);
Vue.component('mav-profile', () => import('./components/MavProfile.vue'));
Vue.component('devise-special-create-included-items', () =>
  import('./components/devise-admin/specials/steps/CreateIncludedItems.vue')
);
Vue.component('specials-slide-out', () =>
  import('./components/glue/specials/SpecialsSlideOut.vue')
);

Vue.component('mav-login', () => import('./components/MavLogin.vue'));

Vue.component('booking-filters', () =>
  import('./components/devise-admin/booking-filters/Filters.vue')
);
Vue.component('slider', () => import('./components/Slider.vue'));

Vue.component('amenities', () => import('./components/Amenities.vue'));
Vue.component('amenities-included', () =>
  import('./components/AmenitiesIncluded.vue')
);
Vue.component('book-now', () => import('./components/BookNow.vue'));

Vue.component('booking-form', () => import('./components/BookingForm.vue'));

Vue.component('mini-inline-booking', () =>
  import('./components/MiniInlineBooking.vue')
);

Vue.component('events-by-day', () => import('./components/EventsByDay.vue'));

Vue.component('form-signup', () => import('./components/FormSignup.vue'));

Vue.component('modal', () => import('./components/Modal.vue'));

Vue.component('grid-gallery', () => import('./components/GridGallery.vue'));
Vue.component('flex-gallery', () => import('./components/FlexGallery.vue'));

Vue.component('single-platform-menu', () =>
  import('./components/SinglePlatformMenu.vue')
);

Vue.component('payment', () => import('./components/Payment.vue'));

Vue.component('pages-explorer', () => import('./components/PagesExplorer.vue'));
Vue.component('reef-my-account', () =>
  import('./components/ReefMyAccount.vue')
);
Vue.component('mav-profile', () => import('./components/MavProfile.vue'));
Vue.component('mav-login', () => import('./components/MavLogin.vue'));

Vue.component('devise-special-edit-versions', () =>
  import('./components/devise-admin/specials/steps/EditVersions.vue')
);
Vue.component('devise-special-edit-content', () =>
  import('./components/devise-admin/specials/steps/EditContent.vue')
);
Vue.component('devise-special-edit-included-items', () =>
  import('./components/devise-admin/specials/steps/EditIncludedItems.vue')
);
Vue.component('devise-special-edit-images', () =>
  import('./components/devise-admin/specials/steps/EditImages.vue')
);

Vue.component('spinner', () => import('./components/Spinner.vue'));
Vue.component('subnavigation', () => import('./components/Subnavigation.vue'));

Vue.component('reef-properties', () =>
  import('./components/ReefProperties.vue')
);

Vue.component('atlantean-pdf-viewer', () =>
  import('./components/AtlanteanPdfViewer.vue')
);

// Vertical Scroll System

Vue.component('vertical-scroll-container', () =>
  import('./components/glue/vertical-scroller/VerticalScrollContainer.vue')
);
Vue.component('vertical-scroll-item', () =>
  import('./components/glue/vertical-scroller/VerticalScrollItem.vue')
);

// Golf

Vue.component('golf-request-information', () =>
  import('./components/GolfRequestInformation.vue')
);
Vue.component('golf-login', () => import('./components/GolfLogin.vue'));
Vue.component('golf-newsletters', () =>
  import('./components/GolfNewsletters.vue')
);
Vue.component('golf-lessons-and-clinics', () =>
  import('./components/GolfLessonsAndClinics.vue')
);

Vue.component('app-staff', () => import('./components/Staff.vue'));

Vue.component('user-icon', () => import('vue-feather-icons/icons/UserIcon'));

Vue.component('mail-icon', () => import('vue-feather-icons/icons/MailIcon'));
Vue.component('facebook-icon', () =>
  import('vue-feather-icons/icons/FacebookIcon')
);
Vue.component('twitter-icon', () =>
  import('vue-feather-icons/icons/TwitterIcon')
);
Vue.component('facebook-icon-closed', () =>
  import('./components/glue/svgs/social/Facebook')
);
Vue.component('twitter-icon-closed', () =>
  import('./components/glue/svgs/social/Twitter')
);
Vue.component('grid-container', () =>
  import('./components/glue/containers/GridContainer.vue')
);

Vue.component('vimeo-slide', () => import('./components/VimeoSlide'));
Vue.component('wistia-slide', () => import('./components/WistiaSlide'));

Vue.component('dining-filter', () =>
  import('./components/glue/dining/DiningFilter.vue')
);
Vue.component('dining-event-slider', () =>
  import('./components/glue/dining/DiningEventsSlider.vue')
);
Vue.component('dining-feature-card', () =>
  import('./components/glue/dining/DiningFeatureCard.vue')
);
Vue.component('blue-flanked-card', () =>
  import('./components/glue/generic-cards/BlueFlankedCard.vue')
);
Vue.component('restaurant-masthead', () =>
  import('./components/glue/dining/RestaurantMasthead.vue')
);
Vue.component('one-third-image-card', () =>
  import('./components/glue/generic-cards/OneThirdImageCard.vue')
);
Vue.component('generic-button', () =>
  import('./components/glue/buttons/GenericButton.vue')
);
Vue.component('accordion-container', () =>
  import('./components/glue/accordions/AccordionContainer.vue')
);
Vue.component('accordion-content-thin', () =>
  import('./components/glue/accordions/AccordionContentThin.vue')
);

Vue.component('tab-system-container', () =>
  import('./components/glue/tab-system/TabSystemContainer.vue')
);
Vue.component('tab-system-tab', () =>
  import('./components/glue/tab-system/TabSystemTab.vue')
);
Vue.component('movie-times', () =>
  import('./components/glue/movies/MovieTimes.vue')
);

Vue.component('circle-slider', () =>
  import('./components/summer/CircleSlider.vue')
);
Vue.component('olapic-slider', () =>
  import('./components/glue/sliders/OlapicSlider.vue')
);

// Experiences
Vue.component('marine-adventures-search', () =>
  import('./components/glue/experience/MarineAdventuresSearch.vue')
);
Vue.component('three-tab-filter', () =>
  import('./components/glue/experience/ThreeTabFilter.vue')
);
Vue.component('activity-card', () =>
  import('./components/glue/experience/ActivityCard.vue')
);
Vue.component('activity-iframe-page', () =>
  import('./components/glue/activities/ActivityIframePage.vue')
);
Vue.component('marine-icon', () =>
  import('./components/glue/experience/MarineIcon.vue')
);

// Events
Vue.component('all-events-index', () =>
  import('./components/glue/events/AllEventsIndex.vue')
);
Vue.component('daily-events', () =>
  import('./components/glue/events/DailyEvents.vue')
);
Vue.component('modal-event-card', () =>
  import('./components/glue/events/ModalEventCard.vue')
);
Vue.component('venue-featured-events', () =>
  import('./components/glue/events/VenueFeaturedEvents.vue')
);

// Blog
Vue.component('blog-index', () =>
  import('./components/glue/blog/BlogIndex.vue')
);
Vue.component('blog-post-wrapper', () =>
  import('./components/glue/blog/BlogPostWrapper.vue')
);

// Press Releases
Vue.component('press-release-index', () =>
  import('./components/glue/press-releases/PressReleaseIndex.vue')
);

// Scavenger Hunt
Vue.component('scavenger-hunt-winning-form', () =>
  import('./components/glue/scavenger-hunt/WinningForm.vue')
);

// Slide Out
Vue.component('slide-out', () =>
  import('./components/glue/alerts/SlideOut.vue')
);

// History
Vue.component('history-group', () =>
  import('./components/glue/history/HistoryGroup.vue')
);
Vue.component('history-item', () =>
  import('./components/glue/history/HistoryItem.vue')
);

// Categorized Gallery
Vue.component('categorized-gallery', () =>
  import('./components/glue/galleries/CategorizedGallery.vue')
);

// General UI
Vue.component('custom-select', () =>
  import('./components/glue/ui/CustomSelect.vue')
);
Vue.component('back-to-top', () =>
  import('./components/glue/ui/BackToTop.vue')
);
Vue.component('date-formatter', () => import('./components/DateFormatter.vue'));

// Devise Slices
Vue.component('slice', () =>
  import('devisephp-interface/src/components/slices/Slice.vue')
);

Vue.component('icon-footer', () =>
  import('./components/glue/footer/IconFooter.vue')
);

// WFF
Vue.component('wff-navigation', () =>
  import('./components/wff/Navigation.vue')
);
Vue.component('wff-general-title-and-copy', () =>
  import('./components/wff/WffGeneralTitleAndCopy.vue')
);
Vue.component('wff-events', () => import('./components/wff/WffEvents.vue'));
Vue.component('wff-video-with-description', () =>
  import('./components/wff/WffVideoWithDescription.vue')
);
Vue.component('wff-slider', () => import('./components/wff/WffSlider.vue'));
Vue.component('wff-chef-color-box', () =>
  import('./components/wff/WffChefColorBox.vue')
);
Vue.component('wff-glasses', () =>
  import('./components/wff/svgs/WffGlasses.vue')
);

// 25th Anniversary
Vue.component('annv-icon-dining', () =>
  import('./components/25th-anniversary/IconDining.vue')
);
Vue.component('annv-icon-discover', () =>
  import('./components/25th-anniversary/IconDiscover.vue')
);
Vue.component('annv-icon-events', () =>
  import('./components/25th-anniversary/IconEvents.vue')
);
Vue.component('annv-icon-immerse', () =>
  import('./components/25th-anniversary/IconImmerse.vue')
);
Vue.component('annv-icon-plan', () =>
  import('./components/25th-anniversary/IconPlan.vue')
);
Vue.component('annv-square-gallery', () =>
  import('./components/25th-anniversary/SquareGallery.vue')
);
Vue.component('memory-game', () =>
  import('./components/25th-anniversary/MemoryGame.vue')
);

// MMW
Vue.component('mmw-mobile-nav', () =>
  import('./components/mmw/MmwMobileNav.vue')
);
Vue.component('music-making-waves-logo', () =>
  import('./components/glue/svgs/MusicMakingWavesLogo.vue')
);
Vue.component('mmw-mini-logo', () =>
  import('./components/glue/svgs/MmwMiniLogo.vue')
);
Vue.component('mmw-slider', () => import('./components/mmw/MmwSlider.vue'));
Vue.component('mmw-olapic-slider', () =>
  import('./components/mmw/MmwOlapicSlider.vue')
);
Vue.component('mmw-divider', () => import('./components/mmw/MmwDivider.vue'));
Vue.component('mmw-event-card', () =>
  import('./components/mmw/MmwEventCard.vue')
);
Vue.component('mmw-form-signup', () =>
  import('./components/mmw/MmwFormSignup.vue')
);

// Careers
Vue.component('careers-redirect', () =>
  import('./components/careers/CareersRedirect.vue')
);


// Formstack
Vue.component('formstack-form', () =>
  import('./components/formstack/formstack-form.vue')
);
Vue.component('formstack-group', () =>
  import('./components/formstack/formstack-group.vue')
);
Vue.component('formstack-text-field', () =>
  import('./components/formstack/formstack-text-field.vue')
);
Vue.component('formstack-textarea-field', () =>
  import('./components/formstack/formstack-textarea-field.vue')
);
Vue.component('formstack-checkbox-field', () =>
  import('./components/formstack/formstack-checkbox-field.vue')
);

// Subnavigation
Vue.component('sub-navigation', () =>
  import('./components/glue/navigation/SubNavigation.vue')
);

// Helpbot
Vue.component('help-bot', () =>
  import('./components/helpbot/HelpBot.vue')
);

// Takeovers
Vue.component('page-takeover', () =>
  import('./components/PageTakeover.vue')
);

// Takeovers
Vue.component('cookie-experiment', () =>
  import('./components/experiments/CookieExperiment.vue')
);

Vue.component('popup-forms', () =>
  import('./components/forms/PopupForms.vue')
);

Vue.component('login-form', () => import('./components/LoginForm.vue'));

Vue.component('slice-preview', () => import('./components/SlicePreview.vue'));
Vue.component('atlantis-alert', () => import('./components/AtlantisAlert.vue'));
Vue.component('glue-app-navigation', GlueAppNavigation);
Vue.component('glue-app-footer', GlueAppFooter);
Vue.component('newsletter-footer-signup', NewsletterFooterSignup);
Vue.component('app-navigation', AppNavigation);
Vue.component('app-footer', AppFooter);
Vue.component('language-selector', LanguageSelector);
Vue.component('glue-language-selector', GlueLanguageSelector);
Vue.component('autograph-logo', AutographLogo);
Vue.component('summer-navigation', SummerNavigation);

/* Barbie */
Vue.component('barbie-navigation', BarbieNavigation);
Vue.component('barbie-footer', BarbieFooter);
Vue.component('barbie-footer-signup', BarbieNewsletterFooterSignup);

// global mixin
Vue.mixin({
  methods: {
    getUrlParameter(name) {
      const n = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp(`[\\?&]${n}=([^&#]*)`);
      // eslint-disable-next-line no-restricted-globals
      const results = regex.exec(location.search);
      return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    userInRole(roleName) {
      if (this.isLoggedIn) {
        const found = this.currentUser.roles.find(
          (role) => role.name === roleName
        );

        if (found) return true;
      }
      return false;
    },
    onLinkCLick(url) {
      // handle switchfly clicks
      const page = window.deviseSettings.$page;
      if (url.includes('secure.atlantisbahamas.com') && page.site_id === 1) {
        const params = url.split('?');
        if (params.length > 1) {
          axios.get('/user-booking-interstitial?' + params[1]);
        }
      }
    },
  },
});

new Vue({
  el: '#app',
  router,
});
