export default {
  bind: function (el, binding, vnode) {
    vnode.data.directives.push({
      name: "v-view",
      def: (e) => {
        console.log(e);
      },
    });
  },
};
