import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex);

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  api: {
    baseUrl: '/api/devise/',
  },
  appState: {
    mobileMenuOpen: false,
  },
  adminMenu: [
    {
      icon: 'CreateIcon',
      routeName: 'devise-page-editor',
      permissions: 'manage slices',
    },
    {
      icon: 'DocumentIcon',
      label: 'Page Elements',
      routeName: 'devise-page-elements-manager',
      permissions: 'manage page elements',
    },
    {
      icon: 'ImageIcon',
      label: 'Media',
      routeName: 'media-manager',
      permissions: 'manage media',
    },
    {
      icon: 'CogIcon',
      label: 'Settings',
      routeName: 'devise-settings',
      permissions: ['manage settings', 'manage atlantis admin'],
      menu: [
        {
          label: 'Atlantis Settings',
          children: [
            {
              label: 'AB Testing',
              routeName: 'devise-ab-tests-admin',
              permissions: 'manage users',
            },
            {
              label: 'Blog Posts',
              routeName: 'devise-blog-posts-admin',
              permissions: 'manage users',
            },
            {
              label: 'Chatbot Pages',
              routeName: 'devise-chatbot-pages-admin',
              permissions: 'manage users',
            },
            {
              label: 'Chatbot Logs & Feedback',
              routeName: 'devise-chatbot-feedback-admin',
              permissions: 'manage users',
            },
            {
              label: 'Calendar Alerts',
              routeName: 'devise-calendar-alerts-admin',
              permissions: 'manage calendar alerts',
            },
            {
              label: 'Cobrands',
              routeName: 'devise-cobrands-admin',
              permissions: 'manage cobrands',
            },
            {
              label: 'Events',
              routeName: 'devise-events-admin',
              permissions: 'manage events',
            },
            {
              label: 'Form Signups*',
              routeName: 'devise-form-signups-index',
              permissions: 'manage form signups',
            },
            {
              label: 'Menus',
              routeName: 'devise-menus-admin',
              permissions: 'manage menus',
            },
            {
              label: 'Specials',
              routeName: 'devise-specials-admin',
              permissions: 'manage specials',
            },
            {
              label: 'Specials Categories',
              routeName: 'devise-specials-categories-admin',
              permissions: 'manage specials',
            },
            {
              label: 'Tiers',
              routeName: 'devise-tiers-admin',
              permissions: 'manage tiers',
            },
            {
              label: 'Travel Agents',
              routeName: 'devise-travel-agents-admin',
              permissions: 'manage travel agents',
            },
            {
              label: 'Pdfs',
              routeName: 'devise-pdfs-admin',
              permissions: 'manage pdf',
            },
            {
              label: 'Press Releases',
              routeName: 'devise-press-releases-admin',
              permissions: 'manage users',
            },
            {
              label: 'Booking Error Logs',
              routeName: 'devise-error-logs-admin',
              permissions: 'manage booking errors',
            },
            {
              label: 'Waiting List',
              routeName: 'devise-waitlist-admin',
              permissions: 'manage calendar alerts',
            },
            {
              label: 'Movie Times',
              routeName: 'devise-movies-admin',
              permissions: 'manage movies',
            },
          ],
          permissions: [
            'manage calendar alerts',
            'manage cobrands',
            'manage events',
            'manage form signups',
            'manage menus',
            'manage specials',
            'manage tiers',
            'manage travel agents',
            'manage booking errors',
            'manage movies',
          ],
        },
        {
          label: 'Rooms & Towers',
          children: [
            {
              label: 'Towers',
              routeName: 'devise-towers-admin',
              permissions: 'manage towers',
            },
            {
              label: 'Rooms',
              routeName: 'devise-rooms-admin',
              permissions: 'manage towers',
            },
            {
              label: 'Room Types',
              routeName: 'devise-room-types-admin',
              permissions: 'manage towers',
            },
            {
              label: 'Restaurants',
              routeName: 'devise-restaurants-admin',
              permissions: 'manage dining',
            },
          ],
          permissions: ['manage towers', 'manage dining'],
        },
        {
          label: 'MAV',
          children: [
            {
              label: 'FAQs',
              routeName: 'devise-booking-faqs-admin',
              permissions: 'manage booking faqs',
            },
            {
              label: 'Link Query Strings',
              routeName: 'devise-booking-link-query-strings-admin',
              permissions: 'manage booking link query strings',
            },
            {
              label: 'Messages',
              routeName: 'devise-booking-messages-admin',
              permissions: 'manage booking messages',
            },
            {
              label: 'Products',
              routeName: 'devise-booking-offers-admin',
              permissions: 'manage booking offers',
            },
          ],
          permissions: [
            'manage booking faqs',
            'manage booking link query strings',
            'manage booking messages',
            'manage booking offers',
          ],
        },
        {
          label: 'Golf',
          children: [
            {
              label: 'Newsletters',
              routeName: 'devise-newsletters-admin',
              permissions: 'manage newsletters',
            },
            {
              label: 'Events',
              routeName: 'devise-events-admin',
              permissions: 'manage events',
            },
            {
              label: 'Form Signups',
              routeName: 'devise-form-signups-admin',
              permissions: 'manage form signups',
            },
            {
              label: 'Golf Pros / Availabilities',
              routeName: 'devise-golf-pros-admin',
              permissions: 'manage golf pros',
            },
          ],
          permissions: [
            'manage newsletters',
            'manage events',
            'manage form signups',
            'manage golf pros',
          ],
        },
        {
          label: 'Site Settings',
          children: [
            {
              label: 'Pages',
              routeName: 'devise-pages-admin',
              permissions: 'manage pages',
            },
            {
              label: 'SEO Page Meta',
              routeName: 'devise-seo-admin',
              permissions: 'manage seo pages',
            },
            {
              label: 'Users',
              routeName: 'devise-users-atl-admin',
              permissions: 'manage users',
            },
            {
              label: 'Global Meta',
              routeName: 'devise-meta-manage',
              permissions: 'manage meta',
            },
            {
              label: 'Sites',
              routeName: 'devise-sites-admin',
              permissions: 'manage sites',
            },
            {
              label: 'Languages',
              routeName: 'devise-languages-manage',
              permissions: 'manage languages',
            },
            {
              label: 'Redirects',
              routeName: 'devise-redirects-atl-admin',
              permissions: 'manage redirects',
            },
            {
              label: 'Route Refresh',
              routeName: 'devise-route-refresh-admin',
              permissions: 'manage pages',
            },
          ],
          permissions: [
            'manage pages',
            'manage users',
            'manage meta',
            'manage sites',
            'manage languages',
            'manage redirects',
          ],
        },
      ],
    },
  ],
  bookingLinkQueryStrings: {
    data: [],
  },
  bookingFaqs: {
    data: [],
  },
  bookingMessages: {
    data: [],
  },
  bookingOffers: {
    data: [],
  },
  calendarAlerts: {
    data: [],
  },
  categories: {
    data: [],
  },
  cobrands: {
    data: [],
  },
  cobrandOverride: null,
  events: {
    data: [],
  },
  formSignups: {
    data: [],
  },
  menus: {
    data: [],
  },
  pageElements: {
    Cobrand: [],
    Special: [],
    Event: [],
  },
  pageRestaurants: [],
  pageRestaurantsCategories: [],
  restaurants: {
    data: [],
  },
  rooms: {
    data: [],
  },
  tags: {
    data: [],
  },
  roomTypes: {
    data: [],
  },
  specials: {
    data: [],
  },
  pageSpecials: [],
  pageSpecialsCategories: [],
  tiers: {
    data: [],
  },
  towers: {
    data: [],
  },
  travelAgents: {
    data: [],
  },
  reefListings: [],
  mainBookNowBtnEnabled: true,
  subMenu: [],
};

// A Vuex instance is created by combining the state, the actions,
// and the mutations. Because the actions and mutations are just
// functions that do not depend on the instance itself, they can
// be easily tested or even hot-reloaded (see counter-hot example).
//
// You can also provide middlewares, which is just an array of
// objects containing some hooks to be called at initialization
// and after each mutation.
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
