<script>
export default {
  data() {
    return {
      hideBookNow: false,
      lastKnownScrollPosition: 0,
      showMobileNav: false,
      hasEyebrow: false,
    };
  },

  mounted() {
    const interval = setInterval(() => {
      const height = this.setNavHeight();
      if (height !== 0) {
        clearInterval(interval);
      }
    }, 1000);

    this.addBusEvents();
    this.addLogoutWatcher();
    this.addScrollWatcher();
  },

  methods: {
    openNavigationItem(item) {
      if (!item.open) {
        this.$set(item, "open", true);
      } else {
        // eslint-disable-next-line no-param-reassign
        item.open = false;
      }
    },
    setNavHeight() {
      let height = 0;
      const navigation = document.getElementById("navigation");
      if (navigation) {
        ({ height } = navigation.getBoundingClientRect());
      }

      const mobileNavigation = document.getElementById("mobile-navigation");
      if (mobileNavigation) {
        ({ height } = mobileNavigation.getBoundingClientRect());
      }

      const spacer = document.getElementById("navigation-spacer");
      if (spacer) {
        spacer.style.height = `${height}px`;
      }

      return height;
    },

    addScrollWatcher() {
      const supportPageOffset = window.pageXOffset !== undefined;
      const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
      window.addEventListener("scroll", () => {
        // eslint-disable-next-line no-nested-ternary,max-len
        this.lastKnownScrollPosition = supportPageOffset
          ? window.pageYOffset
          : isCSS1Compat
          ? document.documentElement.scrollTop
          : document.body.scrollTop;
      });
    },

    addLogoutWatcher() {
      const links = document.getElementsByTagName("a");
      for (let i = 0; i < links.length; i += 1) {
        if (links[i].href.includes("logout")) {
          links[i].addEventListener("click", () => {
            document.getElementById("dvs-logout-form").submit();
          });
        }
      }
    },

    addBusEvents() {
      window.deviseSettings.$bus.$on("hide-navation-book-now", () => {
        this.hideBookNow = true;
      });

      window.deviseSettings.$bus.$on("has-eyebrow", () => {
        this.hasEyebrow = true;
      });
    },
  },

  computed: {
    headerMenu() {
      return window.deviseSettings.$page.site.data.headerMenu;
    },
    mainMenu() {
      return window.deviseSettings.$page.site.data.mainMenu;
    },
    belowFold() {
      if (this.lastKnownScrollPosition > 200) {
        return true;
      }
      return false;
    },
  },
};
</script>
