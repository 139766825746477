<template>
  <div>
    <div v-if="menu.menu_items">
      <mobile-navigation  v-if="showMobile" :menu="menu" :can-access-golf="canAccessGolf"></mobile-navigation>
      <desktop-navigation v-else :menu="menu" :can-access-golf="canAccessGolf"></desktop-navigation>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlueAppNavigation',

  props: {
    canAccessGolf: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DesktopNavigation: () => import('./DesktopNavigation.vue'),
    MobileNavigation: () => import('./MobileNavigation.vue'),
  },

  computed: {
    showMobile() {
      return (
        this.breakpoint === 'mobile' ||
        this.breakpoint === 'tablet' ||
        this.breakpoint === 'desktop' ||
        this.breakpoint === 'largeDesktop'
      );
    },


    headerMenu() {
      return window.deviseSettings.$page.site.data.headerMenu || {menu_items: []};
    },
    menu() {
      if (window.deviseSettings.$page.site.id === 1)
      return window.deviseSettings.$page.site.data.glueMainMenu;
      else
      return window.deviseSettings.$page.site.data.mainMenu || {menu_items: []}
    },
  },
};
</script>
