<template>
  <div v-if="show" class="bg-orange-50 h-auto md:h-96 pt-20 mb-0 md:mb-32">
    <div class="relative mx-auto container">
      <div
        class="tracking-footer-sign-up bg-glueblue-700 text-white text-center flex flex-col items-center pt-16 pb-8 px-8 md:p-12 mb-20 mx-8 md:mx-0"
        style="max-width: 1248px"
      >
        <div
          class="text-4xl font-sans font-light uppercase leading-tight tracking-wider mb-6 md:mb-2"
        >
          Be the first to know
        </div>
        <div
          class="font-sans text-sm uppercase tracking-widest mb-12 md:mb-8 mx-6 md:mx-0"
        >
          SIGN UP TO RECEIVE ATLANTIS NEWSLETTERS AND OFFERS IN YOUR INBOX
        </div>
        <div action="" class="w-full flex justify-center mb-6" v-if="!complete">
          <div class="flex w-full md:w-3/5">
            <input
              type="text"
              class="bg-glueblue-600 placholder-white text-xs leading-none font-sans2 w-full px-6 py-3"
              name="email"
              id="footer-signup-email"
              placeholder="Enter Email Address"
              v-model="email"
            />
            <input
              type="submit"
              class="bg-glueblue-400 text-xs font-bold tracking-widest hidden uppercase font-sans px-8 py-5 leading-none md:block"
              style="height: 51px; width: 184px; padding-top: 1.5em"
              value="Send me offers"
              :class="{ 'opacity-75 cursor-not-allowed': !formValid }"
              @click="submitForm"
              :disabled="!formValid"
            />
          </div>
        </div>

        <div v-if="!complete">
          <div class="flex flex-col justify-center items-center mb-8">
            <label
              for="footer-newsletter-agree-to-terms"
              class="font-sans2 overflow-hidden flex items-center pb-2"
            >
              <div
                class="bg-glueblue-600 w-6 h-6 mr-2 cursor-pointer flex justify-center items-center"
                @click="agreeToTerms = !agreeToTerms"
              >
                <check-icon v-if="agreeToTerms"></check-icon>
              </div>

              <div class="text-left md:text-center">
                I am over the age of 18 and have read the
                <a
                  href="https://www.atlantisbahamas.com/privacy "
                  class="border-b-2 border-glueblue-400 pb-1 text-white"
                >
                  Privacy Policy
                </a>
              </div>
            </label>
            <div class="w-full md:w-1/2 text-xs font-sans2 opacity-50 mt-4">
              By submitting this form, I confirm I would like to receive emails on
              promotions, special offers and updates from Atlantis Paradise Island and its
              affiliated brands.
            </div>
          </div>

          <input
            type="submit"
            class="bg-glueblue-400 text-xs font-bold tracking-widest uppercase w-full font-sans px-8 py-4 pt-5 leading-none md:hidden"
            value="Send me offers"
            :class="{ 'opacity-75 cursor-not-allowed': !formValid }"
            @click="submitForm"
            :disabled="!formValid"
          />
        </div>

        <div v-else class="text-xl font-sans uppercase">
          Thank you, we have added you to our newsletter
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Strings from "../../../mixins/Strings";

export default {
  name: "GlueNewsletterFooterSignup",
  components: {
    CheckIcon: () => import("vue-feather-icons/icons/CheckIcon"),
  },
  mixins: [Strings],
  data() {
    return {
      show: true,
      formName: "page_email_signup",
      complete: false,
      error: null,
      agreeToTerms: false,
      email: "",
      showTerms: false,
    };
  },
  mounted() {
    if (this.currentPage && this.currentPage.hasOwnProperty("title")) {
      this.formName = "page_" + this.snakeCase(this.currentPage.title) + "_email_signup";
    }

    window.bus.$on("sapphire-replacement", () => {
      this.show = false;
    });
  },
  computed: {
    formValid() {
      return this.agreeToTerms && this.email.length > 0;
    },
  },
  methods: {
    submitForm() {
      if (this.currentPage.site_id === 1 && window.dataLayer) {
        window.dataLayer.push({
          event: "e_newsletterSignup",
        });
      }

      const data = {
        form: this.formName,
        agree_to_terms: this.agreeToTerms ? 1 : 0,
        email: this.email,
      };
      axios
        .post("/api/devise/form-signup", data)
        .then(() => {
          this.complete = true;
        })
        .catch((e) => {
          const { errors } = e.response.data;
          console.log(errors);
        });
    },
  },
};
</script>
